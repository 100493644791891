/* ----- Header ----- */
.navbar {
	position: fixed;
	overflow: hidden;
	top: 0;
	z-index: 10;
	background-color: var(--main-color);
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.navbar-image {
	height: 25px;
	-webkit-filter: brightness(0) invert(1);
	filter: brightness(0) invert(1);
	object-fit: contain;
}

.list-button {
	position: absolute;
	left: 0;
	width: 35px;
	margin-left: 15px;
	background: none;
	border: none;
	cursor: pointer;
}

.version {
	position: absolute;
	right: 0;
	width: 55px;
	margin-left: 15px;
	background: none;
	border: none;
	cursor: pointer;
	color: black;
}

.list-button:hover {
	background-color: rgba(8, 95, 60, 0.75);
	border-radius: 10px;
	transition: all 0.3s ease;
}
/* ------------------- */

/* ----- Sidebar ----- */
.sidebar-container {
	position: fixed;
	margin-top: -20px;
	height: 100vh;
	width: 250px;
	transition: all 0.3s ease;
	background-color: rgb(250, 249, 249);
}

.menu-bar {
	height: calc(100% - 55px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-y: scroll;
}

.menu-bar ul.bottom-links {
	align-self: flex-start; 
	margin-top: auto; 
}

.menu-bar::-webkit-scrollbar {
	display: none;
}

.sidebar-container li {
	height: 40px;
	list-style: none;
	display: flex;
	align-items: center;
	margin: 5px 15px 0px -30px;
}

.icon {
	color: var(--main-color);
	min-width: 50px;
	border-radius: 5px;
	transition: all 0.3s ease;
}

.sidebar-container .text {
	color: var(--main-color);
	font-size: 16px;
	font-weight: 500;
	white-space: nowrap;
	opacity: 1;
	list-style: none;
}

.sidebar-container li a {
	list-style: none;
	background-color: transparent;
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	border-radius: 5px;
	text-decoration: none;
}

.sidebar-container li a:hover {
	background-color: var(--main-color);
}

.sidebar-container li a:hover .icon,
.sidebar-container li a:hover .text {
	color: white;
}

/* media query */
@media screen and (min-width: 100px) {
	.showAllSidebar {
		width: 75px;
	}

	.showAllSidebar .nav-link span {
		display: none;
	}
}
/* ------------------- */
