.search-bar-container {
	display: flex;
	height: 40px;
	align-items: center;
	border: 1px solid #cecdcd;
	border-radius: 5px;
	padding-left: 5px;
}

.search-bar-custom {
	border: none;
	font-weight: bold;
	color: #444444;
}

.search-bar-custom::placeholder {
	color: #8d8d8d;
}

.search-icon {
	margin-right: 5px;
	color: #8d8d8d;
}

.data-table-button-custom,
.data-table-edit-item-button-custom,
.data-table-delete-item-button-custom,
.data-table-add-item-button-custom,
.data-table-delete-button-custom {
	display: flex;
	margin-right: 20px;
	height: 40px;
	width: 100px;
	background-color: var(--main-color);
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
}

.data-table-delete-button-custom {
	background-color: rgb(201, 45, 45);
}

.data-table-edit-item-button-custom {
	background-color: transparent;
	background-repeat: no-repeat;
	color: var(--main-color);
}

.data-table-delete-item-button-custom {
	background-color: transparent;
	background-repeat: no-repeat;
	color: red;
}

.data-table-add-item-button-custom {
	width: 150px;
	background-color: transparent;
	background-repeat: no-repeat;
	color: var(--main-color);
}

.export-chart-data-button-custom {
	height: 30px;
	background-color: var(--main-color);
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
}

.send-data-button-custom {
	display: flex;
	height: 40px;
	width: 300px;
	justify-content: center;
	align-items: center;
	background-color: var(--main-color);
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-weight: bold;
}

.icon-style {
	vertical-align: middle;
	margin-right: 5px;
}

.footer {
	display: flex;
	margin: 50px 0px 0px 0px;
	width: 100%;
	height: 50px;
}
