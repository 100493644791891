.date-selector-container {
	margin: 20px 10px 10px 20px;
	/* float: left; */
}

.date-container {
	display: flex;
	align-items: center;
	height: 25px;
	border: none;
	border-bottom: 1px solid var(--main-color);
}

.select-period-button {
	display: flex;
	align-items: center;
	border: none;
	background-color: transparent;
	border-bottom: 1px solid var(--main-color);
	cursor: pointer;
}

.overlay {
	position: absolute;
	margin-top: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 499;
}

.popup {
	position: fixed;
	width: 300px;
	top: 50%;
	left: 50%;
	padding: 20px;
	transform: translate(-50%, -50%);
	background-color: rgb(250, 249, 249);
	border-radius: 10px;
	z-index: 500;
}

.div-flex {
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.date-type-title {
	font-weight: normal;
	font-size: 14px;
	margin-right: 10px;
}

.input-date {
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.input-period {
	border: none;
	border-bottom: solid;
	border-width: 1px;
	background-color: transparent;
	width: 65%;
}

.div-border {
	border-bottom: 1px solid #dadada;
	margin: 10px 0px 10px 0px;
}

.select-data-button {
	width: 100%;
	margin: 30px 0px 10px 0px;
	cursor: pointer;
	background-color: transparent;
	border: none;
	color: var(--main-color);
}
