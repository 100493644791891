.message-container {
	display: flex;
	justify-content: center;
	height: 300px;
}

.message-content-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	/* margin: 20px; */
}

.message-text-style {
	text-align: center;
	font-weight: 600;
	font-size: 14px;
	margin-top: 20px;
}
