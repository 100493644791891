.background {
	background-image: url("../../assets/login-background.jpg");
	background-size: cover;
	background-position: center;
	display: flex;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
}

.container {
	border: 3px solid #dbe0e3;
	border-radius: 10px;
	display: flex;
	min-width: 20%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.5);
}

.palmaflex-logo {
	margin: 20px 0px 20px 0px;
	height: 30%;
	width: 30%;
}

.credencials-container {
	display: flex;
	position: relative;
	width: 90%;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border-width: 0px;
	border: none;
	margin: 10px;
	background-color: rgba(255, 255, 255, 0.63);
}

.login-input {
	width: 100%;
	height: 30px;
	border-radius: 10px;
	border-width: 0px;
	border: none;
	padding-left: 15px;
	font-weight: bold;
	color: rgb(95, 95, 95);
}

.password-button {
	position: absolute;
	right: 10px;
	border-radius: 10px;
	border: none;
	outline: none;
	background-color: rgba(255, 255, 255, 0.63);
	color: rgb(95, 95, 95);
}

.loading-login,
.buttonText {
	width: 90%;
	display: flex;
	justify-content: center;
	border: none;
	border-radius: 10px;
	background-color: #4472c4;
	color: white;
	font-weight: bold;
	text-align: center;
	padding: 10px 0px 10px 0px;
	margin: 10px 0px 20px 0px;
}

.password-button:hover,
.buttonText:hover {
	cursor: pointer;
}

.error-container {
	width: 90%;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	border: 1px solid #f6c7cb;
	background-color: #f8d7da;
	color: #922e3f;
	font-size: 14px;
	margin: 10px 0px 10px 0px;
}

.a-help {
	font-size: 14px;
	text-decoration: none;
	color: var(--main-color);
	margin-bottom: 10px;
	font-weight: bold;
}
