.page {
	margin-top: 60px;
	margin-right: 20px;
}

/* Lists container */
.loading-container {
	display: flex;
	justify-content: center;
}

.table-container {
	flex: 1;
	margin: 20px 20px 0px 20px;
	justify-content: center;
	z-index: 100px;
}

.buttons-container {
	flex: 1;
	display: flex;
	width: 100%;
	flex-direction: row-reverse;
	align-items: center;
	@media (max-width: 400px) {
		flex-direction: column;
		gap: 10px;
	}
}

.admin-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.admin-title {
	color: var(--main-color);
	font-size: 14px;
	font-weight: 500;
	margin-top: 15px;
}

.admin-input {
	border: none;
	border-bottom: 1px solid var(--main-color);
	margin-top: 10px;
	outline: none;
	width: 300px;
}

.admin-input-disabled {
	border: none;
	border-bottom: 1px dotted var(--main-color);
	margin-top: 10px;
	outline: none;
	width: 300px;
	pointer-events: none;
}

.item-style {
	margin: 0px 10px 0px 0px;
	accent-color: var(--main-color);
}

.item-text {
	font-size: 14px;
	margin-top: 15px;
}

.checkbox-container {
	display: flex;
	flex-direction: row;
	padding-top: 15px;
	padding-bottom: 15px;
}

.checkbox-text {
	font-size: 14px;
}

.checkbox-style {
	margin: 0px 10px 0px 0px;
	accent-color: var(--main-color);
}

/* ----------------------- Device-Models Params and Soil Params ----------------------- */
.device-model-params {
	border: none;
	border-bottom: 1px solid var(--main-color);
	margin-top: 10px;
	outline: none;
	width: 100px;
}

.soil-params {
	border: none;
	border-bottom: 1px solid var(--main-color);
	margin-top: 10px;
	outline: none;
	width: 90px;
}

.param-container {
	margin: 10px 0px 10px 0px;
}

.param-text {
	font-size: 14px;
}

/* ----------------------- Password Button ----------------------- */
.change-password-button {
	width: 305px;
	margin-top: 30px;
	border: none;
	border-bottom: 1px solid var(--main-color);
	background-color: transparent;
	color: var(--main-color);
}

.style-password-button {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.right-container {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

/* ----------------------- Tab Navigation ----------------------- */
ul.nav {
	width: 100%;
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	border-bottom: 1px solid #d6d6d6;
	padding-left: 0px;
	@media (max-width: 300px) {
		width: 90%;
	}
}
ul.nav li {
	padding: 1rem;
	list-style: none;
	text-align: center;
	cursor: pointer;
	transition: all 0.3s;
}
ul.nav li:first-child {
	border-radius: 0;
}

ul.nav li:last-child {
	border-radius: 0;
}
ul.nav li:hover {
	border-bottom: 2px solid var(--main-color);
}
ul.nav li.active {
	border-bottom: 2px solid var(--main-color);
	color: var(--main-color);
	font-weight: bold;
	transition: all 0.3s;
}

/* ----------------------- Device Status and Device-Tree ----------------------- */
/* Device-Tree */
.device-tree-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.redirect-button-custom {
	margin-left: -15px;
	display: flex;
	background-color: transparent;
	background-repeat: no-repeat;
	color: var(--main-color);
	border: none;
	align-items: center;
	cursor: pointer;
}

.device-tree-icon-style {
	margin-left: 20px;
	margin-right: 10px;
}

/* Device-Status */
.grid-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 20px;
	width: 100%; /* Ajuste para ocupar 100% da largura da tela */
}

.grid-container-fertigation {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
	gap: 20px;
	width: 100%; /* Ajuste para ocupar 100% da largura da tela */
}

.panel-container {
	position: relative;
	border-radius: 10px;
	background-color: rgb(250, 249, 249);
}

.panel-container-title {
	display: flex;
	height: 30px;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-color: rgb(40, 92, 71);
}

.panel-title {
	color: white;
	text-align: center;
}

.status-panel-items-title,
.status-panel-items,
.status-panel-items-1 {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.status-panel-items-title {
	padding-left: 15px;
	padding-top: 15px;
}

.status-panel-items {
	padding-left: 30px;
	padding-top: 10px;
}

.panel-items-title {
	font-weight: bolder;
	font-size: 14px;
}

.tree {
	--spacing: 1.5rem;
	--radius: 10px;
	margin-left: 5px;
	padding-left: 0;
}

.tree li {
	display: block;
	position: relative;
	padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
	margin-top: 10px;
}

.tree ul {
	margin-left: calc(var(--radius) - var(--spacing));
	padding-left: 0;
}

.tree ul li {
	border-left: 2px solid #ddd;
}

.tree ul li:last-child {
	border-color: transparent;
}

.tree ul li::before {
	content: "";
	display: block;
	position: absolute;
	top: calc(var(--spacing) / -2);
	left: -2px;
	width: calc(var(--spacing) + 2px);
	height: calc(var(--spacing) + 1px);
	border: solid #ddd;
	border-width: 0 0 2px 2px;
}

.tree summary {
	display: block;
	cursor: pointer;
	font-size: 16px;
	text-decoration: underline;
	font-weight: bold;
	color: var(--main-color);
}

.tree summary::marker,
.tree summary::-webkit-details-marker {
	display: none;
}

.tree summary:focus {
	outline: none;
}

.tree summary:focus-visible {
	outline: 1px dotted #000;
}

.tree li::after,
.tree summary::before {
	content: "";
	display: block;
	position: absolute;
	top: calc(var(--spacing) / 2 - var(--radius));
	left: calc(var(--spacing) - var(--radius) - 1px);
	width: calc(2 * var(--radius));
	height: calc(2 * var(--radius));
	border-radius: 50%;
}

.tree summary::before {
	z-index: 1;
	background: var(--main-color) url("../../assets/expand-collapse.svg") 0 0;
	top: calc(
		var(--spacing) / 2 - var(--radius)
	); /* Ajuste a posição vertical */
	left: calc(
		var(--spacing) - var(--radius) - 1px
	); /* Ajuste a posição horizontal */
}

.tree details[open] > summary::before {
	background-position: calc(-2 * var(--radius)) 0;
}

.span-li {
	font-weight: 500;
	text-align: center;
	margin-right: 10px;
}

.open-panel-button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25px;
	border: none;
	background-color: transparent;
	cursor: pointer;
}
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 500;
}

.panel-chart-container {
	position: fixed;
	width: 360px;
	height: 470px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgb(250, 249, 249);
	padding: 20px;
	z-index: 1000;
	border: 1px solid gray;
	border-radius: 10px;
}

.device-status-panel-title {
	display: flex;
	justify-content: center;
	margin-top: 10px;
	font-size: 14px;
	font-weight: bold;
	color: var(--main-color);
}

.close-panel-button {
	position: absolute;
	cursor: pointer;
	border: none;
	background-color: transparent;
	top: 10px;
	right: 10px;
}
