.container-hidrometro {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #5a9bd4; /* Fundo azul */
  border-radius: 10%;
}

.circle-hidrometro {
  width: 200px;
  height: 200px;
  background-color: #fff; /* Cor de fundo branco */
  border-radius: 50%;
  border: 10px solid #ffc107; /* Borda amarela */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Adicionado para evitar que o conteúdo se aproxime demais da borda */
}

.inner-circle-hidrometro {
  text-align: center;
  max-width: 100%; /* Garante que o conteúdo não ultrapasse o contêiner */
}

.value-hidrometro {
  font-size: 32px;
  font-weight: bold;
  border: 2px solid black;
  padding: 5px 10px;
  margin: 10px 0;
  word-wrap: break-word; /* Quebra o texto se for muito longo */
  max-width: 100%; /* Limita a largura do texto ao contêiner */
  box-sizing: border-box; /* Garante que o padding e a borda sejam contabilizados no tamanho total do elemento */
  overflow-wrap: break-word; /* Quebra o texto se necessário */
}
