.password-page-container {
	display: flex;
	height: 100vh;
	justify-content: center;
	align-items: center;
}

.password-panel {
	display: flex;
	padding: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
}

.page-title {
	font-size: 16px;
	font-weight: bolder;
}

.p-password {
	font-size: 14px;
}

.email-input-style,
.password-input-container {
	display: flex;
	align-items: center;
	height: 35px;
	width: 300px;
	border: 1px solid gray;
	border-radius: 10px;
}

.email-input-style {
	margin-top: 20px;
}

.password-input-style {
	width: 250px;
	height: 30px;
	border-radius: 10px;
	border-width: 0px;
	border: none;
	padding-left: 15px;
	font-weight: bold;
}

.show-hide-password-button {
	right: 10px;
	border-radius: 10px;
	border: none;
	outline: none;
	background-color: rgba(255, 255, 255, 0.63);
	color: rgb(95, 95, 95);
}
