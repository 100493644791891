.header-dashboard,
.station-title,
.message-panel {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	flex-wrap: wrap;
}

.header-dashboard-text {
	font-size: 16px;
	font-weight: bold;
	margin-right: 10px;
	text-align: center;
}

.select-gateway-dropdown-button {
	margin-left: 20px;
	width: 200px;
	border: none;
	border-bottom: 1px solid var(--main-color);
	background-color: transparent;
	color: var(--main-color);
}

.style-dropdown-button {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.right-container {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.dropdown-button-container {
	width: 100%;
	margin-bottom: 20px;
	border: none;
	border-top: 2px solid var(--main-color);
	border-bottom: 2px solid var(--main-color);
	border-radius: 10px;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-button-style {
	width: 100%;
	display: flex;
	align-items: center;
	border: none;
	background-color: transparent;
}

.dropdown-button-item-container {
	width: 100%;
	margin-bottom: 20px;
	border: none;
	border: 1px solid var(--main-color);
	border-radius: 10px;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-button-line {
	border: none;
	border-top: 1px solid #e1e2e3;
	padding-top: 10px;
}

.button-title {
	margin-left: 10px;
	color: var(--main-color);
}

.dropdown-button-text {
	margin-left: 10px;
}

.dropdown-button-arrow {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-right: 20px;
}

.show-data-big-container,
.show-data-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

@media (min-width: 400px) {
	.little-panel-container {
		margin: 0px 10px 10px 10px;
		width: 400px;
	}
}

@media (max-width: 400px) {
	.header-dashboard {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.show-data-container {
		flex-direction: column;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0px 10px 10px 10px;
	}

	.little-panel-container {
		height: auto;
		margin-bottom: 20px;
	}

	/* .big-panel-container {
		margin: 10px;
	} */

	.button-title {
		font-size: 12px;
	}
}
